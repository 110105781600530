@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.toast-root {
  --scale: calc(1 - var(--index) * 0.075);
  --translateY: calc(var(--index) * -5px);
  position: absolute;
  bottom: calc(var(--index) * var(--gap)) !important;
  z-index: calc(var(--total) - var(--index)) !important;
  scale: var(--scale);
  transform: translateY(var(--translateY)) translateX(0);
  opacity: 1;
  transition:
    opacity 0.3s ease-in-out,
    bottom 0.3s ease-in-out,
    transform 0.3s ease-in-out,
    scale 0.3s ease-in-out;
}

.toast-root:not([data-state='closed']) {
  animation: slideIn 0.3s ease-in-out forwards;
}

.toast-root[data-state='closed'] {
  animation: slideOut 0.3s ease-in-out forwards;
}
