.authorization_button_social {
    height: 50px;
}

 .authorization{
     max-width: 450px;
}
/*

.authorization > div > .supabase-auth-ui_ui-container:first-child {
    position: relative;
}

.authorization form button {
   justify-content: center;
} */

.authorization input {
    /* max-width: 300px; */
}

.authorization  div > .supabase-auth-ui_ui-container:first-child > div { 
 
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px !important;
    width: 300px;
    /* margin: auto; */
    margin: auto;
}

/* .supabase-auth-ui_ui-container > div {
    text-align: center;
    margin-bottom: 10px;
}
*/
.horny-input_authorization {
    height: 50px;
    width: 300px;
} 