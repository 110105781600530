/* .subscription-bg {
    background: linear-gradient(0deg, #B34D0D, #B34D0D),
linear-gradient(180deg, #FFA66E 0%, #E76B1E 100%);
box-shadow: 0px 1px 1.5px 0px rgba(255, 192, 153, 1) inset;

box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);


} */

.subscription {
  /* width: 393px; */
}

.subscription_grid {
  grid-template-columns: 1fr 1fr;
}
.boosty_input {
  font-size: 12px;
}
.boosty_input:not(:focus)::before {
  display: flex;
  position: absolute;
  pointer-events: none;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  align-items: center;
  font-size: 12px;
}
