.horny-button {
    border-radius: var(--border-20);
    line-height: 16px;
    
    max-width: 333px;
}

.horny-button_short {
    height: 25px;
}

.horny-button_middle {
    height: 40px !important;
    padding: 10px 20px;
    width: 250px;
    max-width: 75%;
}

.horny-button_invite-shop {
    height: 40px !important;
    padding: 10px 20px;
    width: 175px;
}

.horny-button_long {
    height: 40px !important;
    padding: 10px 15px;
    width: 350px;
    max-width: 100%;
}

.horny-button_shorter {
    height: 34px !important;
    padding: 10px 15px;
    width: 250px;
    max-width: 75%;
}

.horny-button_tall {
    height: 50px;
    padding: 15px 20px;
    width: 300px;
}
