/*
=/
*/
.top-bot-filters .horny-shadow-just-shadow-red, .top-bot-filters .horny-shadow-red-with-orange
 {
    box-shadow: 0px 4px 4px 0px rgb(51 51 51 / 50%) !important;

}

.top-bot-filters .tags-selector-border_last  {
    border-image: none !important;
    border: unset !important;
}