.MainLoader {
    height: 100dvh;
    width: 100vw;
    left: 0;
    right: 0;
    top: 0; 
    bottom: 0;
    
}


.MainLoader_container{
    max-width: 333px;
}