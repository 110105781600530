@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
}

body {
  margin: 0;
  /* overflow-y: scroll; */
  overflow: hidden;
  /* background-image: url('https://teasegames.b-cdn.net/teasegames/ui_elements/client/web/public/img/background_main.jpeg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-dark);
}
body * {
  text-align: start;
  font-family:
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar-corner {
  background-color: transparent !important;
  width: 0px;
}
::-webkit-scrollbar {
  background-color: transparent;
  width: 0px;
}
::-webkit-scrollbar-thumb {
  width: 0px !important;
}
::-webkit-scrollbar {
  width: 0px !important;
}
:root {
  --scrollbar-width: 8px;

  --bg-primary: rgba(0, 0, 0, 0.1) !important;

  /* Override rules here */

  /* Colors: Variables received from uikit */

  --color-red-dark: #a90c25;
  --color-red: #da1c3a;
  --color-red-light: #e94560;
  --color-pink: #f4697f;
  --color-pink-light: #f894a4;

  --bs-danger: #a90c25;
  --bs-danger-rgb: 233, 69, 96;

  --bs-red: #da1c3a;
  --bs-pink: #e94560;
  --bs-light-subtle: #f4697f;
  --bs-danger-border-subtle: #f894a4;

  --ds-text-danger: #a90c25;
  --ds-text-red: #da1c3a;
  --ds-text-pink: #e94560;
  --ds-text-light-subtle: #f4697f;
  --ds-text-danger-border-subtle: #f894a4;

  /* --ds-text-accent-red: #ae2a19;
  --ds-text-accent-red-bolder: #601e16;
  --ds-text-accent-orange: #974f0c;
  --ds-text-accent-orange-bolder: #5f3811; */

  --bs-success: #08735b;
  --bs-green: #139578;
  --bs-cyan: #2f9f86;
  --bs-teal: #20c997;
  --bs-success-border-subtle: #7ed2bf;

  --ds-text-success: #08735b;
  --ds-text-green: #139578;
  --ds-text-cyan: #2f9f86;
  --ds-text-teal: #20c997;
  --ds-text-success-border-subtle: #7ed2bf;

  /* --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0c66e4;
  --bs-secondary: #44546f; */

  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-warning: #ffa66e;
  --bs-warning-border-subtle: #ffc099;

  --ds-text-orange: #fd7e14;
  --ds-text-yellow: #ffc107;
  --ds-text-warning: #ffc099;

  --color-green-dark: #08735b;
  --color-green: #139578;
  --color-green-light: #2f9f86;
  --color-salad: #4eb69f;
  --color-salad-light: #7ed2bf;

  --color-brown-dark: #b34d0d;
  --color-brown: #e76b1e;
  --color-brown-light: #f78c49;
  --color-orange: #ffa66e;
  --color-orange-light: #ffc099;

  --background-grey: rgba(85, 85, 85, 1);
  --background-grey-opac: rgba(85, 85, 85, 0.9);

  --background-dark-grey: rgba(51, 51, 51, 1);

  --background-dark: rgba(0, 0, 0, 0.75);
  --background-dark-half: rgba(0, 0, 0, 0.5);

  --background-black: rgba(0, 0, 0, 1);
  --background-white: rgba(255, 255, 255, 1);
  --background-gradient-red: linear-gradient(180deg, #e94560 0%, #da1c3a 100%);
  --background-gradient-orange: linear-gradient(
    180deg,
    rgb(247, 140, 74) 0%,
    rgb(232, 106, 28) 100%
  );
  --background-gradient-green: linear-gradient(
    180deg,
    #2f9f86 0%,
    #139578 100%
  );
  --background-gradient-grey: linear-gradient(180deg, #555555 0%, #333333 100%);

  --color-white: var(--color-white-base);
  --color-gray: #848eac;
  --color-gray-light: #e3ebf6;
  --color-gray-extra-light: #f9fafe;
  --color-dark: #303e67;
  --color-dark-medium: #374777;
  --color-dark-light: #d6d6e7;
  --color-blue: var(--color-teal-600);
  --color-secondary: #9ba7ca;
  --color-secondary-dark: #7081b9;
  --color-secondary-light: #f1f5fa;
  --color-blue-50: #f0f9ff;
  --color-blue-700: #026aa2;

  --color-gray: rgba(51, 51, 51, 0.9);

  /* BG */
  --background-main: #f9fafb;
  --background-ghost: rgba(255 255 255 / 40%);
  --background-overlay: rgba(0 0 0 / 40%);

  /* Font sizes new */

  --font-8: 8px;
  --font-11: 11px;
  --font-13: 13px;
  --font-14: 14px;
  --font-16: 16px;
  --font-18: 18px;
  --font-24: 24px;
  --font-40: 40px;

  /* Font weights */
  --font-weight-normal: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 700;

  /* Shadows */
  --shadow: 0 0 16px rgb(0 0 0 / 4%);
  --shadow-l: 0 -8px 16px rgb(48 62 103 / 5%);

  /* Z-indexes */
  --z-index-notifications: 2000;
  --z-index-popup-above: 1210;
  --z-index-sidebar: 1000;
  --z-index-header: 900;
  --z-index-sub: 800;
  --z-index-modal: 1200;
  --z-index-tooltip: 1000;

  --height-25: 25px;
  --height-40: 40px;

  --border-20: 20px;
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--color-gray-50);
}

::-webkit-scrollbar-thumb {
  border-radius: var(--radius-l);
  background: var(--color-gray-200);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-400);
}

html {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
}

html * {
  font-family: Ubuntu, Inter, Roboto, Oxygen, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

html *:focus {
  outline: none !important;
}

body {
  /* overflow-y: scroll; */
  color: white;

  margin: 0 !important;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

/* #storybook-root {
  width: max-content;
  margin: auto;
  position: relative;
} */
