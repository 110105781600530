#root,
.MainLoader {
  background: radial-gradient(
      circle at top left,
      transparent 9%,
      #353535 10%,
      #353535 15%,
      transparent 16%
    ),
    radial-gradient(
      circle at bottom left,
      transparent 9%,
      #353535 10%,
      #353535 15%,
      transparent 16%
    ),
    radial-gradient(
      circle at top right,
      transparent 9%,
      #353535 10%,
      #353535 15%,
      transparent 16%
    ),
    radial-gradient(
      circle at bottom right,
      transparent 9%,
      #353535 10%,
      #353535 15%,
      transparent 16%
    ),
    radial-gradient(circle, transparent 25%, #333 26%),
    linear-gradient(
      45deg,
      transparent 46%,
      #353535 47%,
      #353535 52%,
      transparent 53%
    ),
    linear-gradient(
      135deg,
      transparent 46%,
      #353535 47%,
      #353535 52%,
      transparent 53%
    );
  background-size: 6em 6em;
  background-color: #333;
  opacity: 1;
}

.App {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
}

.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.horny-tab-background-image {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.1;
  height: 90%;
  margin: auto;
  z-index: -1;
}

.horny-opacity-10 {
  opacity: 0.1;
}
.horny-bg-gradient_red {
  background-image: var(--background-gradient-red);
}

.horny-bg-gradient_orange {
  background-image: var(--background-gradient-orange);
}

.horny-bg-gradient_orange_light {
  background: linear-gradient(180deg, #ffa66e 0%, #ffc099 100%) !important;
}

.horny-bg-gradient_grey {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(0deg, #c3c3c3, #c3c3c3);
}

.horny-bg-gradient-gray-tab {
  background: linear-gradient(180deg, #555 0%, #333 100%);
}

.horny-bg-dark_opac {
  background: rgba(0, 0, 0, 0.84);
}

.horny-bg-gradient_green {
  background: var(--background-gradient-green);
}
.horny-bg-gradient_grey {
  background: var(--background-gradient-grey);
}

.horny-bg-gradient_pink {
  background: linear-gradient(90deg, #f894a4 0%, #f4697f 100%);
}

.horny-bg-dark_half {
  background: var(--background-dark-half);
}

.horny-bg-dark_75 {
  background: rgba(0, 0, 0, 0.75);
}

.horny-break-words {
  word-wrap: break-word;
  hyphens: manual;
}

.horny-bg_grey {
  background: var(--background-grey);
}

.horny-bg_grey_opac {
  background: rgba(85, 85, 85, 0.95);
}

.horny-bg_grey_dark {
  background: var(--background-dark-grey);
}

.horny-bg-dark-gray {
  background: #333;
}

.border_grey {
  border-color: var(--background-grey) !important;
}

.border_orange_light {
  border-color: var(--color-orange-light) !important;
}

.horny-shadow {
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 2px 3px 0px rgba(248, 148, 164, 1) inset !important;

  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 3px 0px rgba(255, 255, 255, 0.4) inset; */
  /* box-shadow: 0px 2px 3px 0px rgba(248, 148, 164, 1) inset; */
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
}

.horny-shadow-red {
  box-shadow:
    0px 4px 4px 0px rgba(169, 12, 37, 0.5),
    0px 2px 3px 0px rgba(248, 148, 164, 1) inset !important;
}

.horny-shadow-red-just-shadow {
  box-shadow: 0px 4px 4px 0px rgba(169, 12, 37, 0.5) inset !important;
}

.horny-bg-red {
  background: rgba(169, 12, 37, 0.5);
}

.horny-shadow-red-with-orange {
  box-shadow:
    0px 4px 4px 0px rgba(169, 12, 37, 0.5),
    0px 1px 2px 0px rgba(255, 192, 153, 1) inset !important;
}

.horny-shadow-just-shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.horny-shadow-just-shadow-dark {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
}

.horny-shadow-just-shadow-red {
  box-shadow: 0px 4px 4px 0px rgba(169, 12, 37, 0.5);
}

.horny-shadow-just-shadow-gray {
  box-shadow: 0px 4px 4px 0px rgb(38 38 38 / 50%);
}

.horny-shadow-orange {
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 2px 3px 0px rgba(255, 192, 153, 1) inset;
}

.horny-shadow-gray {
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 2px 3px 0px rgba(255, 255, 255, 0.4) inset;
}

.horny-text-shadow {
  text-shadow: 0 0 2px black;
}

.horny-shadow_orange_dark {
  box-shadow: -2px 0px 1.5px 0px rgba(179, 77, 13, 0.4);
}

.horny-inner-opacity-half > * {
  opacity: 0.5;
}

.horny-disabled {
  opacity: 0.7;
  pointer-events: none;
}

.horny-text_tiny {
  font-size: 10px;
  line-height: 11px;
}

.horny-text_very_tiny {
  font-size: 8px;
  line-height: 6px;
}

.horny-text_tinies {
  font-size: 7px;
}

.horny-valign-inline {
  vertical-align: middle;
}

.horny-valign-top {
  vertical-align: top;
}

.mw-main {
  max-width: 600px;
}

.horny-text_small {
  font-size: 8px;
  line-height: 6px;
}

.horny-text_medium {
  font-size: 12px;
  line-height: 14.5px;
}

.horny-border_100 {
  border-radius: 100px;
}

.horny-border_3 {
  border-radius: 3px;
}

.horny-border_transparent {
  border: solid 1px transparent;
}

.horny-border-gray {
  border-color: var(--color-gray);
}

.horny-fs-max {
  font-size: 32px;
}

.horny-bg-gradient-black_opac {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.01)
  );
}

.tags-selector-border {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-image: linear-gradient(
    to right,
    rgba(255, 192, 153, 0),
    rgba(255, 192, 153, 0.5),
    rgba(255, 192, 153, 0)
  );
  border-image-slice: 1;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.prompt-picker-border {
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  border-image-slice: 1;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.find-character-picker-border {
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  border-image-slice: 1;
}

.horny-spin {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.horny-height_medium {
  height: 40px;
}

.horny-control {
  max-width: 300px;
}

.horny-control_input::placeholder {
  opacity: 0.5;
}

.horny-text_orange {
  color: rgba(179, 77, 13, 1) !important;
}

.horny-toggle .form-check-input,
.horny-toggle .form-check-input:hover {
  cursor: pointer !important;
  --bs-form-check-bg: rgb(150, 150, 150) !important;
  --bs-form-check-hover-bg: rgba(150, 150, 150) !important;
  --bs-form-check-checked-bg: rgba(255, 166, 110, 1) !important;
  --bs-form-check-checked-hover-bg: rgba(255, 166, 110, 1) !important;
  height: 1.3em;
  width: 2.6em;
}

.horny-toggle .form-check-label {
  color: white;
  opacity: 0.75;
  padding-left: 0.5em;
}

.horny-toggle {
  display: flex;
  align-items: center;
}

.horny-border-bottom {
  border-bottom: 2px solid;
  border-image: linear-gradient(
    to right,
    rgba(255, 192, 153, 0.05),
    rgba(255, 192, 153, 0.5),
    rgba(255, 192, 153, 0.05)
  );
  border-image-slice: 1;
}

.horny-border-bottom-white {
  border-bottom: 2px solid;
  border-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.05)
  );
  border-image-slice: 1;
}

.horny-input:-webkit-autofill,
.horny-input:-webkit-autofill:hover,
.horny-input:-webkit-autofill:focus,
.horny-input:-webkit-autofill:active {
  color: rgba(179, 77, 13, 1) !important;
  -webkit-text-fill-color: rgba(179, 77, 13, 1) !important;
  -webkit-box-shadow: 0 0 0 1000px #ffc099 inset !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  background: linear-gradient(180deg, #ffa66e 0%, #ffc099 100%) !important;
  /* transition: background-color 1s linear; */
}

.horny-text_white {
  color: rgba(255, 255, 255, 1) !important;
}

.horny-new-input::placeholder {
  color: rgba(1255, 255, 255, 0.5) !important;
}

.horny-new-input-outer {
  border-radius: 5px;
  border: 0.5px solid #333;
  background: #666;
  box-shadow:
    0px 2px 2px 0px rgba(0, 0, 0, 0.25),
    0px 2px 3px 0px rgba(0, 0, 0, 0.25) inset;
}

.horny-shadow_grey {
  /* box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset; */
  box-shadow:
    0px 2px 2px 0px rgb(29 29 29 / 59%),
    0px 2px 3px 0px rgb(0 0 0 / 52%) inset;

  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 2px 3px 0px rgba(0, 0, 0, 0.25) inset; */
}

.horny-bg-gradient_grey_opac {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 50.5%
  );
}

.horny-bg-gradient_black_opac {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 50.5%
  );
}

.horny-fit-content {
  width: -moz-fit-content;
  width: fit-content;
}

.horny-small-avatar {
  border-radius: 999px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  height: 40px;
  width: 40px;
}

@keyframes background-color {
  0% {
    background-image: linear-gradient(
      180deg,
      #ffa66e 0%,
      #ffc099 100%
    ) !important;
  }

  100% {
    background-image: linear-gradient(
      180deg,
      #ffa66e 0%,
      #ffc099 100%
    ) !important;
  }
}

.horny-input::placeholder {
  color: rgba(179, 77, 13, 0.5) !important;
}

.horny-gap_tiny {
  gap: 4px;
}

.horny-gap_small {
  gap: 10px;
}

.horny-gap_middle {
  gap: 20px;
}

.button_height_tall {
  height: 50px;
}

.button_height_middle {
  height: 40px;
}

.button_height_small {
  height: 34px;
}

.button_height_tiny {
  height: 25px;
}

.w-max-content {
  width: max-content !important;
}

.mw-max-content {
  max-width: max-content;
}

.min-vw-100 {
  width: 100vw;
}

.h-0 {
  height: 0px !important;
}

.lvh-100 {
  height: 100dvh !important;
}

.horny-opacity-90 {
  opacity: 0.9;
}

.horny-absolute_full {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.w-0 {
  width: 0px !important;
}

.min-dvh-100 {
  min-height: 100dvh;
}

.hyphens-auto {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.text-decoration-underline {
  text-underline-offset: 4px;
}

.scale-up-ver-bottom {
  -webkit-animation: scale-up-ver-bottom 0.4s
    cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-ver-bottom 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.cursor-pointer {
  cursor: pointer;
}

.no-underline-on-hover a:hover {
  text-decoration: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-3-5 23:29:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-ver-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-ver-bottom {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}

@keyframes scale-up-ver-bottom {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}

/* Table */

.flex-table-row-header {
  border-bottom: 1px solid #fff;
}

.flex-table-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.flex-table-row:last-child {
  border-bottom: none;
}

.flex-table-col:first-child {
  padding-left: 0;
}

.horny-disable-scrollbar {
  /* Оставляет возможность скролла, но скрывает саму полосу */
  scrollbar-width: none; /* Для Firefox */
  -ms-overflow-style: none; /* Для Internet Explorer и Edge */
}

.horny-disable-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari и Opera */
}