.copy-code-block {
  border-radius: 5px;
  border: 0.5px solid #333;
  background: #666;
  box-shadow:
    0px 2px 2px 0px rgba(0, 0, 0, 0.25),
    0px 2px 3px 0px rgba(0, 0, 0, 0.25) inset;
}

.copy-code-copied-text {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.copy-code-copy-button {
    border-radius: 0px 5px 5px 0px;
    background: linear-gradient(180deg, #ACACAC 0%, #747474 100%);
    box-shadow: 0px 1px 1px 0px #FFF inset;
}