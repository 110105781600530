.media {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-auto-rows: 1fr; */
    grid-gap: 10px;
    height: calc(100% - 26px - 10px);
    overflow: overlay;
    align-content: flex-start;
}



.media_container{
    padding-top: 100px;
    height: calc(100%);
}


.media_controls {
    height: 26px;
}