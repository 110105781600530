.slider-wrapper.axis-vertical {
     height: 100% !important;
}

.slider{
    height: 100% !important;
}

.character-carousel ul {
    /* height: 100% !important; */
}

.carousel .slide {
    height: 100%;
    /* height: 100lvh;
    min-height: 100lvh; */
}

.control-arrow.control-prev {

}

.carousel.carousel-slider .control-arrow{
    bottom: unset !important;
}



/* .character-carousel ul li  */
 
.carousel.carousel-slider .control-arrow {
    width: 42px;
    height: 42px;
    padding: 0 !important;
    border-radius: 100px;
    overflow: hidden;
    right: unset !important;
    left: calc(0% + 12px) !important;
    background: rgba(0, 0, 0, 0.5) !important;
    opacity: 1;
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0.5) !important;
}
.carousel .control-prev.control-arrow {
    top: calc(60% - 84px);

}
.carousel .control-next.control-arrow {
    top: 60%;

}
.carousel .control-prev.control-arrow:before {
    transform: rotate(270deg);

}
.carousel .control-next.control-arrow:before {
    transform: rotate(90deg);
}

.carousel.carousel-slider .control-arrow:before {
    border: 0 !important;
    background-image: url('https://teasegames.b-cdn.net/teasegames/ui_elements/client/web/public/img/icon/Play.png');
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: 16px;
    background-repeat: no-repeat;
    margin: 0;
    /* width: 42px;
    height: 42px;
    padding: 0 !important;
    border-radius: 100px;
    overflow: hidden;
    right: unset !important;
    left: calc(50% - 21px) !important;
    background: rgba(0, 0, 0, 0.5) !important;
    opacity: 1; */
}