.NutakuShareBotImage {
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.NutakuShareRefferalBlock {
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    background: linear-gradient(180deg, #666 0%, #333 100%);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.NutakuShareRefferalBlockMobile {
    border-top: 2px solid rgba(255, 255, 255, 0.50);
    background: linear-gradient(180deg, #666 0%, #333 100%);
}

.NutakuShareCodeBlock {
    border-radius: 5px;
    border: 0.5px solid #333;
    background: #666;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 2px 3px 0px rgba(0, 0, 0, 0.25) inset;
}

.NutakuShareCopyButton {
    border-radius: 0px 5px 5px 0px;
    background: linear-gradient(180deg, #ACACAC 0%, #747474 100%);
    box-shadow: 0px 1px 1px 0px #FFF inset;
}

.NutakuCopiedText {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.NutakuShareBlackBG {
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
}

.NutakuShareFitContent {
    width: -moz-fit-content;
    width: fit-content;
  }

.NutakuSharePopupImage {
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.50);
}