.radio-group-item[data-state='checked'] .radio-icon-checked {
  display: inline-block;
}
.radio-group-item[data-state='unchecked'] .radio-icon-checked {
  display: none;
}
.radio-group-item[data-state='unchecked'] .radio-icon-unchecked {
  display: inline-block;
}
.radio-group-item[data-state='checked'] .radio-icon-unchecked {
  display: none;
}