.horny-icon {
    /* object-fit: none; */
    width: 20px !important;
    height: 20px !important;
}

.horny-icon_micro {
    width: 6px !important;
    height: 6px  !important;
}

.horny-icon_tiny {
    width: 8px !important;
    height: 8px  !important;
}

.horny-icon_xs {
    width: 12px !important;
    height: 12px !important;
}

.horny-icon_small {
    width: 16px !important;
    height: 16px !important;
}

.horny-icon_smaller {
    width: 18px !important;
    height: 18px !important;
}

.horny-icon_medium {
    width: 20px;
    height: 20px;
}


.horny-icon_big {
    width: 24px !important;
    height: 24px !important;
}

.horny-icon_xl {
    width: 32px !important;
    height: 32px !important;
}