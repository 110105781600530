.configure_slider {
    max-width: 350px !important;
}

.configure_carousel ul {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.configure_carousel_item {
    width: 80px !important;
}