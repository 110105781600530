@keyframes move {
    0% {transform: translateY(0) }
    100% {transform: translateY(-150px) }
  }
  .swipe-characters{
    bottom: calc(50% - 75px);
    left: calc(50% - 5px);
  }

  .swipe-characters_track {
     background:  linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, rgba(255, 255, 255, 0.0) 136px);
  }