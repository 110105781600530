
.carousel.carousel-slider{
    height: 100%;
}
.carousel-slide_story {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.01));
    /* padding-bottom: 90px; */
}
.carousel-slide_content {
    /* background: rgba(0,0,0,0.1); */
    /* background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)); */
}

.carousel .slide img {
    object-position: center 10%;
}