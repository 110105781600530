.horny-modal {
    background-position-x: center;
    background-position-y: top;
}

/* .horny-modal.horny-bg-dark_opac{
    background: none;
}

.horny-modal.horny-bg-dark_opac:first-of-type{
    background: none;
} */

 